import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpContextToken,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  HttpUtils,
  PaginationDataModel,
  DataWrapper,
  CAREFILE_DATA_WRAPPER,
} from 'c4p-portal-util';
import { CareFileModel } from '../../models/carefile.model';
import { TeamModel } from '../../../team/models/team.model';
import { ReadByModel } from '../../models';

const GATEKEEPER_DATA = new HttpContextToken<DataWrapper>(() => null);

export function cacheIt(dataWrapper: DataWrapper) {
  return new HttpContext().set(GATEKEEPER_DATA, dataWrapper);
}

@Injectable({
  providedIn: 'root'
})
export class CarefileService {
  public carefiles$ = new BehaviorSubject<{
    data: CareFileModel[];
    count: number;
  }>(null);

  public carefile$ = new BehaviorSubject<CareFileModel>(null);
  public get carefileId(): string {
    return this.carefile$.value?.id;
  }

  public get carefileStatus(): string {
    return this.carefile$.value?.status;
  }

  public carefileForm = this.formBuilder.group({
    id: [null],
    applicationNumber: [{ value: null, disabled: true }, Validators.required],
    createdAt: [{ value: null, disabled: true }, Validators.required],
    updatedAt: [{ value: null, disabled: true }, Validators.required],
    updatedBy: [{ value: null, disabled: true }, Validators.required],
    createdBy: [{ value: null, disabled: true }, Validators.required],
    responsibleRegMunicipality: [null, Validators.required],
    type: [null, Validators.required],
    generalInfo: [null],
    assignedToCareFile: [false],
    status: [],
    applier: this.formBuilder.group({
      name: [null, Validators.required],
      phoneNumber: [null],
      email: [null, Validators.email],
      type: [null, Validators.required],
      notes: [null],
    }),
    referrer: this.formBuilder.group({
      name: [null, Validators.required],
      type: [null, Validators.required],
      contactPerson: this.formBuilder.group({
        name: [null],
        phoneNumber: [null],
        email: [null, Validators.email],
      }),
      address: [],
    }),
    client: this.formBuilder.group({
      id: [],
      givenName: [],
      namePrefix: [],
      lastName: [],
      bsn: [],
      gender: [],
      dateOfBirth: [],
    }),
    clientNetworks: this.formBuilder.array([]),
  });

  constructor(private http: HttpClient, private formBuilder: FormBuilder) {}

  public getCarefiles(paginationData: PaginationDataModel, filterData?: any) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles',
    });
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );

    return this.http.get<any>(`${environment.carefileManagementApiUrl}`, {
      headers,
      params,
    });
  }

  public createCarefile(
    applicationId: string,
    clientId: string,
  ): Observable<CareFileModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles',
      entity_type: 'Carefile',
    });
    return this.http.post<CareFileModel>(
      `${environment.carefileManagementApiUrl}`,
      { applicationId, clientId },
      { headers },
    );
  }

  public addToExistingCarefile(
    applicationId: string,
    carefileId: string,
    reopenServices?: string[],
  ): Observable<CareFileModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/application/:application_id',
      entity_type: 'Carefile',
      entity_id: carefileId,
      sub_entity_id: applicationId,
      sub_entity_type: 'application',
    });
    return this.http.put<CareFileModel>(
      `${environment.carefileManagementApiUrl}/${carefileId}/application/${applicationId}`,
      {
        reopenServices,
      },
      {
        headers,
      },
    );
  }

  public assignTeams(
    carefileId: string,
    addedTeams: TeamModel[],
  ): Observable<CareFileModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/teams',
      entity_type: 'Carefile',
      entity_id: carefileId,
      sub_entity_type: 'team',
    });
    return this.http.post<CareFileModel>(
      `${environment.carefileManagementApiUrl}/${carefileId}/teams`,
      { addedTeams },
      { headers },
    );
  }

  public deleteTeam(
    carefileId: string,
    teamId: string,
  ): Observable<CareFileModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/team/:team_id',
      entity_type: 'Carefile',
      entity_id: carefileId,
      sub_entity_id: teamId,
      sub_entity_type: 'team',
    });
    return this.http.delete<CareFileModel>(
      `${environment.carefileManagementApiUrl}/${carefileId}/team/${teamId}`,
      { headers },
    );
  }

  public getCarefile(id: string, type?: string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id',
      entity_type: 'Carefile',
      entity_id: id.toString(),
    });

    let params = new HttpParams();
    if (type) {
      params = params.set('type', type);
    }

    return this.http.get<CareFileModel>(
      `${environment.carefileManagementApiUrl}/${id}`,
      {
        headers,
        params,
      },
    );
  }

  public getCarefileGatekept(
    id: string,
    access: string,
    carefileDataWrapper?: DataWrapper,
    original?: boolean,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id',
      entity_type: 'Carefile',
      entity_id: id.toString(),
      access_type: access,
    });
    const params = HttpUtils.getFilterParams({ original });

    return this.http.get<CareFileModel>(
      `${environment.carefileManagementApiUrl}/${id}`,
      {
        headers,
        params,
        context: new HttpContext().set(
          CAREFILE_DATA_WRAPPER,
          carefileDataWrapper,
        ),
      },
    );
  }

  public getLatestCarefileByClientId(clientId: string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/client/:client_id',
      entity_type: 'Carefile',
      sub_entity_type: 'Client',
      sub_entity_id: clientId,
    });
    return this.http.get<CareFileModel | null>(
      `${environment.carefileManagementApiUrl}/client/${clientId}`,
      {
        headers,
      },
    );
  }

  public getExistingCarefileTeams(carefileId: string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/teams',
      entity_type: 'Carefile',
      entity_id: carefileId,
      sub_entity_type: 'Teams',
    });
    return this.http.get<CareFileModel[] | null>(
      `${environment.carefileManagementApiUrl}/${carefileId}/teams`,
      {
        headers,
      },
    );
  }

  public escalate(carefileId: string, reason: string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/escalate',
      entity_type: 'Carefile',
      entity_id: carefileId,
      access_type: 'escalated',
    });

    return this.http.post<void>(
      `${environment.carefileManagementApiUrl}/${carefileId}/escalate`,
      {
        reason,
      },
      { headers },
    );
  }

  public markAsRead(carefileId: string, readBy: ReadByModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/mark-as-read/:entity/:entity_id',
      entity_type: 'Carefile',
      entity_id: carefileId,
    });

    return this.http.put<ReadByModel>(
      `${environment.carefileManagementApiUrl}/${carefileId}/mark-as-read/${readBy.entity}/${readBy.entityId}`,
      readBy,
      {
        headers,
      },
    );
  }

  public archive(carefileId: string, archiveDate: Date) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/archive',
      entity_type: 'Carefile',
      entity_id: carefileId.toString(),
    });

    return this.http.post<void>(
      `${environment.carefileManagementApiUrl}/${carefileId}/archive`,
      { archiveDate: archiveDate },
      { headers },
    );
  }

  public unarchive(carefileId: string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/unarchive',
      entity_type: 'Carefile',
      entity_id: carefileId.toString(),
    });

    return this.http.post<void>(
      `${environment.carefileManagementApiUrl}/${carefileId}/unarchive`,
      {},
      { headers },
    );
  }

  public cancelArchive(carefileId: string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/archive',
      entity_type: 'Carefile',
      entity_id: carefileId.toString(),
    });

    return this.http.post<void>(
      `${environment.carefileManagementApiUrl}/${carefileId}/archive/cancel`,
      {},
      { headers },
    );
  }
  public archiveClosedCarefiles(
    date: string,
    autoArchiveEnabled: boolean,
  ): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/tenants/settings/carefile/archive',
      entity_type: 'Tenant',
    });
    const body = {
      date: date,
      autoArchiveEnabled: autoArchiveEnabled,
    };
    return this.http.patch(
      `${environment.tenantManagementApiUrl}/settings/carefile/archive`,
      body,
      {
        headers,
      },
    );
  }
}
