import { TenantConfigurationService } from '../system-administration/services/tenant-configuration/tenant-configuration.service';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';
import { AppInitService } from '../../../../c4p-portal-util/src/lib/services/app-initializer/app-init.service';
import { MAIN_MENU_ITEMS } from './values.const';
import { LanguageService } from 'c4p-portal-util';

export function initializeKeycloak(
  keycloak: KeycloakService,
  configService: TenantConfigurationService,
  appInitService: AppInitService,
  languageService: LanguageService
) {
  return async () => {
    languageService.setLanguage('nl');
    const configuration = await configService.getKeycloakConfiguration();

    let keycloakConfig: KeycloakConfig = {
      url: configuration.keycloakHost,
      realm: configuration.keycloakRealmName,
      clientId: 'c4p-employee-portal',
    };
    await keycloak.init({
      config: keycloakConfig,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      bearerExcludedUrls: [],
    });
    return appInitService.initializeUser(MAIN_MENU_ITEMS);
  };
}
