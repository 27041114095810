import { TenantConfiguration } from './../../../employee/models/tenant-configuration.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { TenantModel } from '../../../../../../c4p-portal-tenant/src/app/tenant-management/models/tenant.model';

@Injectable({
  providedIn: 'root',
})
export class TenantConfigurationService {
  private url = `${environment.tenantManagementApiUrl}`;

  constructor(private httpClient: HttpClient) {}

  public async getKeycloakConfiguration(): Promise<TenantConfiguration> {
    const subdomain = window.location.origin.replace('employee.', '');

    return await new Promise((resolve, reject) => {
      this.httpClient
        .get(`${this.url}/configuration?subdomain=${subdomain}`)
        .subscribe((response: TenantConfiguration) => {
          resolve(response);
        });
    });
  }

  public getTenantSettings(): Observable<TenantModel> {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      origin_path: '/tenants/settings',
    });
    return this.httpClient
      .get<TenantModel>(`${environment.tenantManagementApiUrl}/settings`, {
        headers: httpHeaders,
      })
  }
}
