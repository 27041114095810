import { TenantConfigurationService } from './system-administration/services/tenant-configuration/tenant-configuration.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import {
  C4pUtilModule,
  GatekeeperInterceptor, LanguageService,
  MaterialModule,
} from 'c4p-portal-util';
import { InterceptService } from 'c4p-portal-util';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { initializeKeycloak } from './const/keycloak.init';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './const/app-routing.module';
import { QuillModule } from 'ngx-quill';
import { CarefileAccessTypeInterceptor } from './carefile/interceptors/carefile-access.interceptor';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { AgGridModule } from 'ag-grid-angular';
import { AppInitService } from '../../../c4p-portal-util/src/lib/services/app-initializer/app-init.service';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
registerLocaleData(localeNl);

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    KeycloakAngularModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    AgGridModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    C4pUtilModule.forRoot({ ...environment }),
    QuillModule.forRoot({
      modules: {
        clipboard: {
          matchVisual: false,
        },
      },
    }),
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, TenantConfigurationService,AppInitService,LanguageService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GatekeeperInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CarefileAccessTypeInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    provideNgxMask(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
