import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  accessType,
  isCarefileActivated,
} from '../guards/carefile/carefile-guard.service';

@Injectable()
export class CarefileAccessTypeInterceptor implements HttpInterceptor {
  constructor(private activatedRoute: ActivatedRoute) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const url = this.activatedRoute.snapshot['_routerState'].url;
    const entityType = `${url.split('/')[1]}`;
    const carefileId = `${url.split('/')[2]}`;
    if (
      entityType === 'carefiles' &&
      carefileId != 'undefined' &&
      carefileId != 'carefile' &&
      isCarefileActivated
    ) {
      const clonedRequest = request.clone({
        headers: request.headers
          .set('carefile_id', `${url.split('/')[2]}`)
          .set('module_type', `${url.split('/')[1]}`)
          .set('access_type', accessType),
      });
      return next.handle(clonedRequest);
    } else {
      return next.handle(request);
    }
  }
}
